//
// Variables.
//

const inputElement = document.getElementById("mp3Link");

const allowedExtensions = ['mp3', 'ogg', 'webm', 'wav', 'flac', 'aac', 'm4a', 'midi', 'mid', 'opus', 'amr'];
  
const resultElement = document.getElementById("result");
const alertElement = document.getElementById("alert");



//
// Function: Reusable Alert Message.
//

function displayAlert(message, duration = 3000) {
  alertElement.textContent = message;
  alertElement.style.display = "block";

  setTimeout(function() {
    alertElement.style.display = "none";
  }, duration);
}



//
// Function: Paste to clipboard & trigger 'generateAudioCode' function.
//

async function pasteClipboard() {
  const clipboardText = (await navigator.clipboard.readText()).trim();
  const inputExtension = clipboardText.split('.').pop().toLowerCase();

  if (allowedExtensions.includes(inputExtension)) { 

    // If match paste into input box
    inputElement.value = clipboardText;

    // Alert
    displayAlert("Audio link pasted & ready!", 3000);

    generateAudioCode();

  } else {
    // Alert
    displayAlert("Your input is not valid!", 3000);    
  }
}
  


//
// Function: Generate audio code - from pasted input.
//

function generateAudioCode() {
  const audioLink = inputElement.value.trim();
  const audioExtension = audioLink.split('.').pop().toLowerCase();
  let codeExt = null;

  const extensionMap = {
    'mp3': 'mpeg',
    'ogg': 'ogg',
    'webm': 'webm',
    'wav': 'wav',
    'flac': 'flac',
    'aac': 'aac',
    'm4a': 'aac',
    'mid': 'mid',
    'midi': 'midi',
    'opus': 'opus',
    'amr': 'amr',
  };

  codeExt = extensionMap[audioExtension] || null;

  // Compile output string:
  const codeA = '<audio controls style="display: block;"><source src="';
  const codeB = '" type="audio/';
  const codeD = '"></audio>';
  const audioCode = codeA + audioLink + codeB + codeExt  + codeD;
  document.getElementById("result").innerHTML = audioCode;
}



//
// Function: Copy code to clipboard.
//
  
function copyClipboard() {
  var audioCode = document.getElementById("result").value;
  navigator.clipboard.writeText(audioCode).then(function() {

    // Alert
    displayAlert("Audio code copied to clipboard!", 3000); 

    // Reset
    resetPage();
  });
}



//
// Function: Reset 
//
  
function resetPage() {
  inputElement.value = "";
  resultElement.innerHTML = "";
}



//
// Event listener: Run Code
//

// Add an event listener to the button element by its ID.
document.getElementById("copyButton").addEventListener("click", copyClipboard);

// Add an event listener to the button element by its ID.
document.getElementById("pasteButton").addEventListener("click", pasteClipboard);

inputElement.addEventListener("input", generateAudioCode);

// eof